import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import cover from '../../images/about/spkl-hide.png';
import Newsletter from '../../components/Newsletter';

const Media = (props) => {
    const services = props.data.allMarkdownRemark.edges;
    return (
        <Layout bodyClass="page-media">
            <SEO title="Media" />
            <div className="cover-container media">
                <div className="cover-content">
                    <div>
                        <h1 className="hero-title">Media</h1>
                    </div>
                </div>
            </div>
            <div className="py-5">
                <div className="container">
                    <div className="row">
                        {services.map(edge => (
                            <div key={edge.node.frontmatter.path} className="col-md-6 mb-3">
                                <div className="card">
                                    <div className="card-header">
                                        <img className= "blog-img" src={withPrefix(edge.node.frontmatter.image)} />
                                        <div className="post-date">{edge.node.frontmatter.date}</div>
                                        <h2 className="card-title">
                                            <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                                        </h2>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <p>{edge.node.excerpt}</p>
                                            <Link className="read-more" to={edge.node.frontmatter.path}>Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Newsletter></Newsletter>
        </Layout>
    );
};

export const query = graphql`
query mediaQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/media/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date(formatString: "YYYY MMM DD")
            image
          }
          excerpt(pruneLength: 200)
        }
      }
    }
  }
`;
export default Media;
